.pageContainer {
  margin-left: 10px;
  margin-right: 10px;
}

.container {
  display: flex;
  gap: 20px;
  margin: 15px;
}

.imageGallery {
  flex: 1;
  max-width: 65%;
}

.image {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Use 'contain' if you don't want images to be cropped */
}

.details {
  flex: 1;
  max-width: 35%;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;

  overflow-y: auto;
  margin-bottom: 20px;
  align-self: flex-start;

  position: sticky;
  top: 90px;

  overflow-y: auto; /* Allow scrolling */
  max-height: calc(100vh - 150px); /* Adjust the max-height as needed */
}

.details > *:not(:last-child) {
  margin-bottom: 10px;
}

@media screen and (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .imageGallery {
    max-width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .details {
    max-width: 100%;
    order: -1; /* Put details above images on smaller screens */
    align-self: center;

    position: static;
    max-height: none;
  }
}

.my-custom-lightbox .ril__toolbar {
  background-color: rgba(0, 0, 0, 0.1);
}

:global(.ril__toolbar) {
  background-color: rgba(0, 0, 0, 0) !important;
}

.buttonContainer {
  display: flex;
  gap: 10px;
  justify-content: start;
}

.applyButton {
  background-color: #007bff; /* Blue color */
  color: white; /* White text color */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
  padding: 10px 20px; /* Padding */
  cursor: pointer; /* Cursor on hover */
  text-decoration: none; /* No underline */
  display: inline-block; /* Ensure proper spacing and alignment */
  text-align: center; /* Center text */

  width: fit-content;
}

.applyButton:hover {
  background-color: #0056b3; /* Darker blue color on hover */
  text-decoration: none; /* No underline on hover */
}

.editButton {
  background-color: #28a745; /* Green color */
  color: white; /* White text color */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
  padding: 10px 20px; /* Padding */
  cursor: pointer; /* Cursor on hover */
  text-decoration: none; /* No underline */
  display: inline-block; /* Ensure proper spacing and alignment */
  text-align: center; /* Center text */

  width: fit-content;
}

.editButton:hover {
  background-color: #1e7e34; /* Darker green color on hover */
  text-decoration: none; /* No underline on hover */
}

.toggleButton {
  background-color: #ffc107; /* Yellow color to distinguish it */
  color: white; /* White text color */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
  padding: 10px 20px; /* Padding */
  cursor: pointer; /* Cursor on hover */
  text-decoration: none; /* No underline */
  display: inline-block; /* Ensure proper spacing and alignment */
  text-align: center; /* Center text */
  width: fit-content; /* Fit the content width */
}

.toggleButton:hover {
  background-color: #e0a800; /* Darker yellow color on hover */
  text-decoration: none; /* No underline on hover */
}
