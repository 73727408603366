/* EditPropertyDetails.module.css */

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.imageGallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
  max-width: 65%;
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.details {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 35%;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

.details > *:not(:last-child) {
  margin-bottom: 15px;
}

.label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.input,
.textarea,
.select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #5cb85c; /* Bootstrap 'success' green */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #4cae4c; /* Slightly darker green on hover */
}

.button:active {
  transform: scale(0.98); /* Slightly scale down when clicked */
}

.button + .button {
  margin-left: 10px;
  background-color: #d9534f; /* Bootstrap 'danger' red */
}

.button + .button:hover {
  background-color: #c9302c; /* Slightly darker red on hover */
}

@media screen and (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .imageGallery {
    max-width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .details {
    max-width: 100%;
    order: -1; /* Put details above images on smaller screens */
  }
}
