.propertyCard {
  background: #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.propertyCard a {
  color: inherit;
  text-decoration: none;
}

.propertyCard img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.propertyCardContent {
  padding: 15px;
}

.propertyCard h2,
h3 {
  margin-top: 0;
}
