/* HomePage.module.css */

.container {
  text-align: center;
  max-width: 100vw;
  margin: 0 auto;
  padding: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.heroSection {
  max-width: 70vw;
  padding: clamp(5px, 2vh, 600px) clamp(5px, 10vw, 200px);
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-bottom: clamp(20px, 5vh, 40px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name {
  font-size: 3.5vw;
  margin-bottom: 10px;
}

.logo {
  max-width: clamp(100px, 70vw, 500px);
  height: auto;
  margin-bottom: clamp(20px, 2vh, 40px);
}

.ctaButton {
  background-color: #00a6e4;
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  font-size: clamp(10px, 2vw, 18px);
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
}

.ctaButton:hover {
  background-color: #0066cc;
}

.featureSection {
  max-width: 70vw;
  padding: clamp(5px, 2vh, 600px) clamp(5px, 2vw, 200px);
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.propertiesList {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: start;
  flex-wrap: wrap; /* Allow items to wrap if they don't fit */
}

.propertiesList li {
  max-width: clamp(100px, 90%, 400px);

  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  display: grid;
  align-items: start;
}

.propertiesList li:hover {
  transform: translateY(-5px);
}
